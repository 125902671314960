<template>
  <v-container>
    <v-card
      outlined
      rounded="lg"
    >
      <v-toolbar flat>
        <v-toolbar-title> {{ marketplace.name }} </v-toolbar-title>
        <v-spacer />
        <v-btn
          color="black"
          outlined
          class="my-4"
          width="135px"
          @click="showMarketPlaceForm"
          elevation="0"
        >
          {{ $t('edit') }}
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card
      outlined
      class="mb-2"
      rounded="lg"
    >
      <v-tabs
        v-model="tab"
      >
        <v-tab href="#appProfiles">
          {{ $t('appProfiles') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="grey lighten-3"
      v-model="tab"
      :key="forceRenderKey"
    >
      <v-tab-item value="appProfiles">
        <YessProfilesList
          @selectProfile="selectProfile"
          :load-items-callback="getProfiles"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
  import YessProfilesList from "../yess/YessProfilesList.vue"
  export default {
      name: 'MarketplaceProfile',
      props: {
          marketplace: {
              type: Object,
              required: true,
              default: () => {}
          }
      }, 
      components: {
        YessProfilesList
      },
      data() {
        return {
          tab : null, 
          forceRenderKey: 0
        }
      },
      methods: {
        showMarketPlaceForm() {
          this.$store.commit('updateDataToMutate', {
            title: this.$t('updateMarketplace'),
            //SVG's can be cause the URL to be too large
            preventPreviewProperties: ['brightLogo', 'darkLogo', 'appIcon'],
            objectToMutate: this.marketplace,
            formComponent: 'components/marketplace/MarketplaceForm', 
            previewUrl: 'https://marketplace.salescloud.is/#/welcome',
            previewType: 'mobile',
            saveCallback: function(dataToUpdate) {
              delete dataToUpdate.namespace
              delete dataToUpdate.bundleId
              delete dataToUpdate.appleAppId
              delete dataToUpdate.organization
              this.$store.dispatch('updateMarketplace', dataToUpdate).then(res => {
                if(res) {
                  this.$store.commit('updateActionSuccess', {
                    message: this.$t('success'), 
                    subMessage: this.$t('successfullyUpdatedMarketplace')
                  })
                  this.$store.commit('setLastUpdatedObject', res)
                } else {
                  this.$store.commit('updateActionError', {
                    message: this.$t('error'), 
                    subMessage: this.$t('somethingWentWrong')
                  })
                }
              }).catch(() => {
                this.$store.commit('updateActionError', {
                  message: this.$t('error'), 
                  subMessage: this.$t('somethingWentWrong')
                })
              })
            }
          })
        }, 
        getProfiles() {
          return this.$store.dispatch('getYessProfiles', this.marketplace.namespace)
        }
      }, 
      watch: {
        marketplace(newValue) {
          this.forceRenderKey++
      }
    }
  }
</script>
      